import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import CookieConsent from "react-cookie-consent"

const NotFoundPageRu = ({ pageContext }) => (
  <Layout language={pageContext.language} wrongPage={true}>
    <div className="page-not-found__wrapper">
      <div className="container">
        <h1>ОШИБКА 404.</h1>
        <h2>СТРАНИЦА НЕ НАЙДЕНА</h2>
        <p>Запрашиваемая страница не найдена.</p>
        <Link to="/" className="cta-btn cta-btn--hero cta-btn--green">
          На Главную
        </Link>
      </div>
    </div>
    <CookieConsent
      location="bottom"
      buttonText="Принять"
      cookieName="irabigails.com"
      containerClasses={"cookies rounded shadow-lg"}
      buttonClasses={"cookies__btn"}
      expires={150}
    >
      Этот веб-сайт использует файлы cookie, чтобы вы могли максимально
      эффективно использовать наш веб-сайт.
    </CookieConsent>
  </Layout>
)

export default NotFoundPageRu
